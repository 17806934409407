import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">The trailguide team</h1>
    <h3 className="text-center text-lg">The developers who made all this stuff work</h3>
    <br />
    <br />
    <h4>{`Bjørn Jarle Kvande (1972)`}</h4>
    <div className="text-sm text-gray-700 -mt-3">
  Founder, managing director, developer-in-chief, and leader of the board.
    </div>
    <Image src="/news/bjornoffice.jpg" mdxType="Image" />
    <div className="leading-7 sm:leading-9 my-3">
  Bjørn Jarle has a Master of Science degree in Computer Science from Old
  Dominion University in Norfolk, Virginia, USA from 1996. He has been
  programming for 40 years and worked as a professional software developer since
  1996. He has built several digital services and applications for the parent
  company ObjectPlanet since 1998. At Trailguide he is responsible for the
  architecture, design, and development of the Trailguide technology and the
  different apps built on top of our platform. He is fluent in Norwegian,
  English and B1 level Italian, and understands and speaks some German.
  Programming is like a drug to him, and he cannot stay away from it.{" "}
  <Link to="https://www.linkedin.com/in/bjornkvande/" mdxType="Link">LinkedIn profile</Link>.
    </div>
    <br />
    <br />
    <h4>{`Peter Seidl (1987)`}</h4>
    <div className="text-sm text-gray-700 -mt-3">
  Co-Founder, developer, map designer, and product manager.
    </div>
    <Image src="/peter/peter-forest.jpg" mdxType="Image" />
    <div className="leading-7 sm:leading-9 my-3">
  Peter has a Dipl. Ing. (FH) degree in Wood technology from the University of
  applied sciences in Rosenheim from 2011. For Trailguide he designed and
  developed the digital maps and set up the open source technology that runs the
  map services. He is responsible for a lot of the initial content and user base
  growth outside of Norway in the Trailguide app. He has during his time at
  Trailguide taught himself Python and JavaScript and is now a valued member of
  the development team for our technology. He is currently working on GIS
  related solutions. He is fluent in German and English and a B1 level in
  Norwegian.{" "}
  <Link to="https://www.linkedin.com/in/peterseidl01/" mdxType="Link">LinkedIn profile</Link>.
    </div>
    <br />
    <Image src="/news/peter_bjorn_gunther.jpg" mdxType="Image" />
    <div className="leading-7 sm:leading-9 my-3">
  The natural question one might ask is “how were you not able to make a ton of
  money with all this great technology?”
    </div>
    <div className="leading-7 sm:leading-9 my-3">
  The correct question to ask would be “how were you able to get this far
  without a proper business team?”
    </div>
    <div className="leading-7 sm:leading-9 my-3">
  The answer to that is "with a great passion for software development, mountain
  biking, and the outdoors"
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      